import React from 'react'

// import Layout from '@components/Layout'
import Layout from '../components/Layout'
import Image from '../components/Image'
import { Section } from '../components/Layout'
import { t } from '../components/Translator'
// @ts-ignore
import content from '../../content/history.yaml'

const HistoryPage: React.FC = () => {

    const sections = content.sections
    const images = content.images
    const videoTitle = content.video_title

    return (
        <Layout head={content.head} banner={content.banner}>
            <div className="row">
                <div className="col-lg-7 order-lg-2">
                    <Section content={sections[0]}/>
                    <div className="spacer-10"/>
                    <Section content={sections[1]}/>
                    <div className="spacer-20"/>
                </div>
                <div className="col-lg-5 order-lg-1">
                    <Image image={images[0]}/>
                    <div className="spacer-50"/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-5">
                    <Section content={sections[2]}/>
                    <div className="spacer-10"/>
                    <Section content={sections[3]}/>
                    <div className="spacer-20"/>
                </div>
                <div className="col-lg-7">
                    <div className="row">
                        <div className="col-6">
                            <Image image={images[1]}/>
                        </div>
                        <div className="col-6">
                            <Image image={images[2]}/>
                        </div>
                    </div>
                    <div className="spacer-50"/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-7 order-lg-2">
                    <Section content={sections[4]}/>
                    <div className="spacer-10"/>
                    <Section content={sections[5]}/>
                    <div className="spacer-20"/>
                </div>
                <div className="col-lg-5 order-lg-1">
                    <Image image={images[3]}/>
                    <div className="spacer-10"/>
                    <Image image={images[4]}/>
                    <div className="spacer-50"/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-5">
                    <Section content={sections[6]}/>
                    <div className="spacer-10"/>
                    <Section content={sections[7]}/>
                    <div className="spacer-20"/>
                </div>
                <div className="col-lg-7">
                    <div className="row">
                        <div className="col-6">
                            <Image image={images[5]}/>
                        </div>
                        <div className="col-6">
                            <Image image={images[6]}/>
                            <div className="spacer-50"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div style={{ paddingLeft: '15px' }}>
                    <h2>{t(videoTitle)}</h2>
                </div>
                <div style={{ margin: '-5px auto 0 auto', padding: '15px' }}>
                    {/*<video width="800" height="500" preload="auto" controls>*/}
                    <video preload="auto" controls>
                        <source src="/videos/restauratie_afgerond_1080p.mp4" type="video/mp4"/>
                    </video>
                </div>
                <p style={{ margin: '-8px auto 0 auto' }}>© Provincie West-Vlaanderen</p>
            </div>
        </Layout>
    )
}

export default HistoryPage